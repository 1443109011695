import React from 'react';
import { PageProvider } from './src/context';
import './src/i18n'; // Asegúrate de importar i18n aquí

export const wrapRootElement = ({ element }) => (
  <PageProvider>{element}</PageProvider>
);

export const onRouteUpdate = ({ location, prevLocation }) => {
  // Aquí puedes ejecutar tu método
  //console.log("New pathname", location.pathname);
  //console.log("Old pathname", prevLocation ? prevLocation.pathname : null);

  // Por ejemplo, llamar a una función definida en tu layout
  if (typeof window !== "undefined" && window.layoutMethod) {
    window.layoutMethod(location.pathname);
  }
}
