import * as React from "react";
import logo from "../../images/logo.svg";
import mx from "../../images/mx.png";
import us from "../../images/us.png";

import 'animate.css';
import { Button, FormControl, InputAdornment, InputLabel, MenuItem, Select, SelectChangeEvent } from "@mui/material";
import MenuSideBar from "./menuSideBar";
import { navigate } from "gatsby-link";
import Submenu, { Menu } from "./submenu";
import "./navbar.css";
import { Link } from "gatsby";
import { useTranslation } from 'react-i18next';
import { AccountCircle } from "@mui/icons-material";
import { useSnackbar } from "notistack";

type Props = {
  session:any,
  setSession:any,
  signUp: ()=>void,
  signIn: ()=>void,
  id: string,
  menu: {mainMenu:any[], rightMenu:any[]}
}

const Navbar: React.FC<Props> = ({session, setSession, signUp, signIn, id, menu}) => {
  const { enqueueSnackbar } = useSnackbar();
  const [lang, setLang] = React.useState<string>();
  const { i18n } = useTranslation();

  React.useEffect(() => {
    //Set lang if stored in LS
    setLang(localStorage.getItem('lang') || 'es');
  }, [session]);

  function logout() {
    sessionStorage.clear();
    localStorage.clear();
    setSession(false);
    enqueueSnackbar("La sesión ha sido cerrada");
  }

  const handleChangeLang = (event: SelectChangeEvent) => {
    let lang = event.target.value;
    i18n.changeLanguage(lang);
    localStorage.setItem('lang', lang);
    setLang(lang);
  };

  const [datosCliente, setDatosCliente] = React.useState<any>({});
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const openMenu = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    if (anchorEl) {
      setAnchorEl(null);
      return;
    }
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <nav id={id}>
      <Link to="/">
        <img src={logo} alt="Puntazul Logo" id="nav-logo" />
      </Link>
      <section>
        <menu style={{padding: 0}} className="mainMenu">
          {menu.mainMenu.map((menuItem: any, index) => (
            <div className="menuItem initialMenuItem" key={index}>
              {menuItem.submenu ? (
                  <>
                    <Button className="nav-button">{menuItem.name}</Button>
                    <Submenu menuItems={menuItem.submenu} />
                  </>
              ) : 
                menuItem.targetBlank ?
                  <a href={menuItem.path} target="_blank" ><Button className="nav-button" style={{height: "100%"}}>{menuItem.name}</Button></a>
                  :
                  <Button onClick={()=> {navigate(menuItem.path)}} className="nav-button">{menuItem.name}</Button>
              }              
            </div>
          ))}
          {menu.mainMenu.length > 4 &&
            <div className="menuItem masMenuItem">
              <Button className="nav-button">Más</Button>
              <Submenu menuItems={menu.mainMenu.slice(4)} />
            </div>
          }
        </menu> 
      </section>
      <MenuSideBar menuItems={menu.mainMenu.concat(menu.rightMenu)} session={session} setSession={setSession} signIn={signIn} signUp={signUp} />
      <section className="leftMenu" style={{display: "flex", alignItems: "center"}}>
        <menu style={{padding: 0}}>
          {lang &&
            <FormControl sx={{ m: 1 }} size="small">
              <Select
                variant="standard"
                id="language-select"
                value={lang}
                onChange={handleChangeLang}
                MenuProps={{
                  disableScrollLock: true, // Evita bloquear el scroll del body
                }}
                startAdornment={
                  <InputAdornment position="start">
                    <img src={lang === 'es' ? mx : us} alt="test" width={20} />
                  </InputAdornment>
                }
                aria-controls="language-menu"
                >
                <MenuItem value="es">Español</MenuItem>
                <MenuItem value="en">English</MenuItem>
              </Select>
            </FormControl>
          }
          {menu.rightMenu.map((menuItem:any, index) => (
            <div className="menuItem" key={index}>
              {
                menuItem.targetBlank ?
                  <a href={menuItem.path} target="_blank" ><Button className="nav-button" style={{height: "100%"}}>{menuItem.name}</Button></a>
                  :
                  <Button onClick={()=> {navigate(menuItem.path, )}} className="nav-button">{menuItem.name}</Button>
              }
            </div>
          ))}
          { session ? (
              <Button className="nav-button login-button" style={{alignSelf: "center"}} onClick={logout} variant="contained" disableElevation color="carbon">Cerrar Sesión</Button>
          ) : (
              <Button className="nav-button login-button" style={{alignSelf: "center"}} onClick={signIn} variant="contained" disableElevation color="carbon">Login</Button>
          )}
        </menu>
      </section>
    </nav>
  )
}

export default Navbar