import React, { PropsWithChildren, useEffect } from "react"
import Navbar from "../components/navbar/navbar";
import Footer from "../components/footer";
import { usePageContext } from "../context";
import ModalLogin from "../components/modalLogin.jsx";
import SnackbarCloseButton from "../components/components";
import { Box, Button, Grid, IconButton, LinearProgress, Modal } from "@mui/material";

import ArrowIcon from '@mui/icons-material/ArrowUpwardRounded'
import CloseIcon from '@mui/icons-material/Close'
import { Link } from "gatsby";
import MapaDireccion from "../components/mapaDireccion";
import { ObtenerEsquemasCompra, ObtenerEsquemasCompraBody } from "../services/maverick";
import CookiesAlert from "../components/cookies-alert";
import { TimeoutLogic } from "../components/timeoutLogic";
import { useLocation } from '@reach/router';
import { usePageSenseScript, useScriptZoho } from "../hooks/use-script-zoho";
import { useScriptGoogle, useScriptGoogleTwo, useScriptGoogleThree } from "../hooks/use-google-tag";
import { useFbPixel, useFbPixelNoScript } from "../hooks/use-meta-pixel";
import { useLuckyorange } from "../hooks/use-luckyorange";
import CloseButton from "../components/close-button";
import { SnackbarProvider } from "notistack";

import { ThemeProvider, createTheme } from '@mui/material/styles';

// Augment the palette to include an ochre color
declare module '@mui/material/styles' {
  interface Palette {
    carbon: Palette['primary'];
  }

  interface PaletteOptions {
    carbon?: PaletteOptions['primary'];
  }
}

// Update the Button's color options to include an ochre option
declare module '@mui/material/Button' {
  interface ButtonPropsColorOverrides {
    carbon: true;
  }
}

const theme = createTheme({
  palette: {
    primary: {
      main: '#216869',
    },
    carbon: {
      main: '#2A2A2A',
    }
  },
});

export default function MainLayout(props:PropsWithChildren) {
  const pageContext = usePageContext();
  //const [openLogin, setOpenLogin] = React.useState(false);

  const [alertVariant, setAlertVariant] = React.useState("info");
  const [message, setMessage] = React.useState("");
  const [newAlert, setNewAlert] = React.useState(0);

  const [directionMapOpen, setDirectionMapOpen] = React.useState(false);
  const [currentLocation, setCurrentLocation] = React.useState(null);

  const [menu, setMenu] = React.useState({mainMenu: [], rightMenu: []});
    
  const location = useLocation();

  if (process.env.NODE_ENV !== "development" && process.env.TESTING === "false") {
    // useScriptZoho('https://salesiq.zoho.com/widget', 'siqad4d3deffa008f376f2983f63df4990d4cbaf6a71534ca389217dc182e0f2f5d');
    // useScriptGoogle();
    // useScriptGoogleTwo();
    // useScriptGoogleThree();
    // useFbPixel();
    // useFbPixelNoScript();
    // useLuckyorange();
    // usePageSenseScript();
  }

  function openLoginModal() {
    document.getElementById("btnModalLogin")?.click();
  }

  useEffect(() => {
    fetch(process.env.DATA_API_URL + "/menu.json", {
        method: "GET"
    })
    .then(response => response.json())
    .then(response => response.data)
    .then((response) => {
        setMenu(response);
    });
  }, []);

  return (
      <ThemeProvider theme={theme}>
        <SnackbarProvider 
          anchorOrigin={{ vertical: "top", horizontal: "right" }} 
          autoHideDuration={15000} 
          action={snackbarKey => 
            <SnackbarCloseButton snackbarKey={snackbarKey} />
          } 
          className={"SnackBarAleStack"} 
          maxSnack={5}>
          <Navbar id={"navHeader"} 
          session={pageContext?.session}
          setSession={pageContext?.setSession}
          signUp={() => {
            //setLoginView(false);
            //openLoginModal();
          }}
          signIn={() => {
            //pageContext?.setLoginView(true);
            openLoginModal();
          }} 
          menu={menu}
          />
          <div style={{height: 100}}></div>
          <main id="inicio" className={"mainPageWhite"}>
              <div style={{minHeight: "calc(100vh - 282px)"}}>
                { props.children }
              </div>
          </main>
          <Footer clickOnDirection={()=>{setDirectionMapOpen(true)}}/>
          {/* <Grid className={"btnWhatsFloat"}>
            <a target={"_blank"} style={{ margin: "0" }} rel={"noreferrer"} href="https://api.whatsapp.com/send?phone=5216641293808&text=Hola, me gustaría conocer más información acerca de Puntazul">
              <img className={"redIconWhats"} style={{ margin: "0" }} alt="whatsapp" src={"/static-files/PA/whatsappFloatCirculoVerde.png"} />
            </a>
          </Grid> */}
          {/* <Link to={"#inicio"}>
            <Button className={"btntoHeader"}>
              <ArrowIcon></ArrowIcon>
            </Button>
          </Link> */}
          <Modal
            open={directionMapOpen}
            onClose={() => { console.log("Modal cerrado") }}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description">
            <Box id="boxModalDirection" sx={{
              width: 1024,
              maxWidth: "100%",
              height: 600,
              maxHeight: "100%",
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              background: 'white',
              boxShadow: "0px 0px 25px black",
              borderRadius: 4,
            }}
              className="modalBox">
              <div style={{ position: "absolute", background: "white", zIndex: 20, right: currentLocation ? 0 : 50}}>
                <CloseButton onClick={() => { setDirectionMapOpen(false); }} white={true}/>
              </div>
              <MapaDireccion onCoordinatesLoaded={(coordinates: any) => {
                setCurrentLocation(coordinates);
              }} />
            </Box>
          </Modal>
          <ModalLogin
            setSession={pageContext?.setSession} 
            loginView={pageContext?.loginView} 
            setOpenLogin={pageContext?.setOpenLoginModal} 
            openLogin={pageContext?.openLoginModal} 
            onClose={()=>{}}
            canCloseModal={pageContext?.canCloseModal}
          />
          <CookiesAlert />
          {!process.env.TESTING &&      
            <TimeoutLogic session={pageContext?.session} setSession={pageContext?.setSession} />
          }
        </SnackbarProvider>
      </ThemeProvider>

  )
}