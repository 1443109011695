const auth_token = "authToken";
const userSessionStorage = "user";
    
export const getToken = () => {
  return localStorage.getItem(auth_token);
};

export const setToken = (token:string) => {
  if (token) {
    localStorage.setItem(auth_token, token);
  }
};

export const removeToken = () => {
  sessionStorage.removeItem(userSessionStorage);
};

export const getUser = () => {
  let user = sessionStorage.getItem(userSessionStorage);
  if (!user) {return}
  return JSON.parse(user);
};

export const setUser = (user:any) => {
  if (user) {
    sessionStorage.setItem(userSessionStorage, JSON.stringify(user));
  }
};

export const removeUser = () => {
  localStorage.removeItem(auth_token);
};