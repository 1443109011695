import React, { useState } from "react";
import { usePageContext } from "../context";
import { setToken, setUser } from "../utils/helper";

type registerProps = {
  username: string,
  email: string,
  password: string,
  onSuccess: (response: any) => void,
  onError: (error:string) => void,
  onComplete: () => void
}

export const useRegister = (props: registerProps) => {
  const url = `${process.env.API_STRAPI}/auth/local/register`;

  fetch(url, {
    method: "POST",
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      username: props.username,
      email: props.email,
      password: props.password
    })
  })
  .then(response => {    
    if (response.status === 400) {
      throw new Error("El Username o Email ya han sido registrados.");
    }

    if (response.status !== 200) {
      throw new Error("Register error.");
    }
    
    return response.json()
  })
  .then(response => {
    props.onSuccess(response);
  })
  .finally(() => {
    props.onComplete();
  })
  .catch(reason => {
    console.error("Error conectando con el servidor strapi: " + reason);
    props.onError(reason);
  })
};

type loginProps = {
  identifier: string,
  password: string, 
  onSuccess: (response:any) => void, 
  onError: (error:string) => void,
  onComplete: () => void
}

export const useLogin = (props: loginProps) => {
  const url = `${process.env.API_STRAPI}/auth/local`;

  fetch(url, {
    method: "POST",
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      identifier: props.identifier,
      password: props.password
    })
  })
  .then(response => {
    console.log("test response", response);
    if (response.status !== 200) {
      throw new Error("Credenciales incorrectas.");      
    }
    
    return response.json()
  })
  .then(response => {
    setToken(response.jwt);
    setUser(response.user);
    props.onSuccess(response);
  })
  .finally(() => {
    props.onComplete();
  })
  .catch(reason => {
    console.error("Error conectando con el servidor strapi: " + reason);
    props.onError(reason);
  })
};

type forgotPasswordProps = {
  email: string,
  onSuccess: (response:any) => void, 
  onError: (error:string) => void,
  onComplete: () => void
}
export const forgotPassword = (props:forgotPasswordProps) => {
  const url = `${process.env.API_STRAPI}/auth/forgot-password`;

  fetch(url, {
    method: "POST",
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      email: props.email,
    })
  })
  .then(response => {
    console.log("test response", response);
    if (response.status !== 200) {
      throw new Error("Credenciales incorrectas.");      
    }
    
    return response.json()
  })
  .then(response => {
    props.onSuccess(response);
  })
  .finally(() => {
    props.onComplete();
  })
  .catch(reason => {
    console.error("Error conectando con el servidor strapi: " + reason);
    props.onError(reason);
  })
};

type resetPasswordProps = {
  code: string,
  password: string,
  passwordConfirmation: string,
  onSuccess: (response:any) => void, 
  onError: (error:any) => void,
  onComplete: () => void
}
export const resetPassword = (props:resetPasswordProps) => {
  const url = `${process.env.API_STRAPI}/auth/reset-password`;

  fetch(url, {
    method: "POST",
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      code: props.code,
      password: props.password,
      passwordConfirmation: props.passwordConfirmation,
    })
  })
  .then(response => {
    if (response.status === 400) {
      throw new Error("Por favor complete los campos");  
    }
    
    return response.json()
  })
  .then(response => {
    if (response.error) {
      throw new Error(response.error.message);
    }
    props.onSuccess(response);
  })
  .finally(() => {
    props.onComplete();
  })
  .catch(reason => {
    console.error("Error conectando con el servidor strapi: " + reason);
    props.onError(reason);
  })
};