import React, { useState } from 'react';
import { Button, Grid, TextField, Typography } from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useLogin } from '../../hooks/user';
import { usePageContext } from '../../context';

// Esquema de validación con Yup
const schema = yup.object({
    identifier: yup.string().required('This field is required'),
    password: yup.string().min(6, 'Password must be at least 6 characters').required('Password is required'),
}).required();

type LoginProps = {
    onClickRegister: () => void, 
    onClickForgotPassword: () => void,
    onLoginSuccessful: () => void,
    onLoginError: (error:string) => void
}

export default function Login(props: LoginProps) {
    const [loading, setLoading] = useState(false);
    const pageContext = usePageContext();

    const { control, handleSubmit, formState: { errors } } = useForm({
        resolver: yupResolver(schema),
        defaultValues: {
            identifier: '',
            password: '',
        },
    });

    const onSubmit = (data:any) => {
        setLoading(true);
        useLogin({
            identifier: data.identifier,
            password: data.password,
            onSuccess: (response) => {
                console.log('Registration successful:', response);
                pageContext?.setUserData(response.user);
                props.onLoginSuccessful();
            },
            onError: (error) => {
                props.onLoginError(error)
            },
            onComplete: () => {
                setLoading(false);
            }
        });
    };

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <Grid container className='login' style={{padding: 15}}>
                <Grid item xs={12} style={{textAlign: "center", paddingBottom: 20}}>
                    <Typography variant="h3">
                        Bienvenido!
                    </Typography>
                    <Typography variant="subtitle1" gutterBottom>
                        Por favor ingresa tus datos
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Controller
                        name="identifier"
                        control={control}
                        render={({ field }) => (
                        <TextField
                            {...field}
                            label="Email o Usuario"
                            fullWidth
                            error={!!errors.identifier}
                            helperText={errors.identifier?.message || " "}
                        />
                        )}
                    />
                    </Grid>
                    <Grid item xs={12}>
                    <Controller
                        name="password"
                        control={control}
                        render={({ field }) => (
                        <TextField
                            {...field}
                            label="Contraseña"
                            type="password"
                            fullWidth
                            error={!!errors.password}
                            helperText={errors.password?.message || " "}
                        />
                        )}
                    />
                    <div style={{width: "100%", display: "flex", justifyContent: "space-between", paddingBottom: 16}}>
                        <Button onClick={props.onClickRegister}>Registro</Button>
                        <Button onClick={props.onClickForgotPassword}>Olvidé mi contraseña</Button>
                    </div>
                </Grid>
                
                <Grid item xs={12} style={{textAlign: "right"}}>
                </Grid>
                <Grid item xs={12}>
                    <Button variant="contained" fullWidth type="submit" disabled={loading}>Iniciar Sesión</Button>
                </Grid>
            </Grid>
        </form>
    )
}