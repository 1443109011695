import * as React from "react";
import { Grid, Button, Modal } from "@mui/material";
import Login from "./auth/login";
import CloseButton from "./close-button";
import Register from "./auth/register";
import { usePageContext } from "../context";
import ForgotPassword from "./auth/forgot-password";
import { useSnackbar } from "notistack";

const ModalLogin = ({ setSession, setOpenLogin, openLogin = false, onClose, loginView, canCloseModal = true}) => {
  const pageContext = usePageContext();
  const { enqueueSnackbar } = useSnackbar();
  const [showedView, setShowedView] = React.useState("login");

  const showSnackbar = (message, variant) => {
    enqueueSnackbar(message, {variant});
  }

  const renderAuthView = () => {
    switch (showedView) {
      case 'login': 
        return (
          <Login 
            setSession={setSession} 
            setOpenLogin={setOpenLogin} 
            onLoginSuccessful={() => {
                showSnackbar("Inicio de sesión exitoso, bienvenido.", "success");
                pageContext?.setSession(true);
                handleCloseLogin();
            }}
            onLoginError={error => {
                showSnackbar(error.message, "error");
            }}
            loginView={loginView} 
            canCloseModal={canCloseModal} 
            onClickRegister={() => {setShowedView("register")}}
            onClickForgotPassword={() => {setShowedView("forgot")}}
          />
        )
      case 'register':
        return (
          <Register 
            onClickLogin={() => {setShowedView("login")}}
            onLoginSuccessful={() => {
                showSnackbar("Registro exitoso, revise tu correo para confirmar tu Email.", "success");
                
                handleCloseLogin();
            }}
            onLoginError={error => {
                showSnackbar(error.message, "error");
            }}
          />
        )          
      case 'forgot':
        return (
          <ForgotPassword
            onClickLogin={() => {setShowedView("login")}}
            onLoginSuccessful={() => {                
            }}
            onError={error => {
            }}
          />
        )
    }      
  }

  const handleOpenLogin = () => setOpenLogin(true);
  const handleCloseLogin = (event, reason) => {
    if (reason !== 'backdropClick') {
      setOpenLogin(false)
    }
  };

  return (
    <React.Fragment>
      <Button id="btnModalLogin" style={{ display: "none" }} onClick={handleOpenLogin}></Button>

      <Modal className={"modal modalResponsiveBottom"}
        open={openLogin}
        onClose={handleCloseLogin}
        disableEscapeKeyDown={true}
        disableScrollLock={false}>
        <Grid className={"divContainerModalLogin"}>
          <CloseButton onClick={handleCloseLogin} />
          {renderAuthView()}
        </Grid>
      </Modal>
    </React.Fragment>
  );
};

export default ModalLogin;
